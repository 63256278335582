<template>
  <div>
    <link
      href="https://fonts.googleapis.com/css2?family=Amatic+SC:wght@700&display=swap"
      rel="stylesheet"
    />
    <div class="fullscreen text-center flex flex-center">
      <div>
        <div class="img-404" ref="container">
          <div class="eye1" ref="eye1">
            <div class="point1" ref="point1"></div>
          </div>
          <div class="eye2" ref="eye2">
            <div class="point2" ref="point2"></div>
          </div>
          <img src="/assets/img404.svg" alt="404" />
        </div>
        <div class="text-404 mb-6">
          Упс! :( Что-то мы не можем<br />
          ничего загрузить. Сорьки
        </div>

        <BmButton label="Попробовать снова" rounded size="lg" />
      </div>
    </div>
  </div>
</template>

<script>
import {arcctg} from '@src/utils/helpers'

export default {
  name: 'Error404',
  data() {
    return {
      mouse: {
        x: undefined,
        y: undefined,
      },
    }
  },
  computed: {
    offsetEye1() {
      return {
        left:
          this.$refs.eye1.getBoundingClientRect().left +
          this.$refs.eye1.getBoundingClientRect().width / 2,
        top:
          this.$refs.eye1.getBoundingClientRect().top +
          this.$refs.eye1.getBoundingClientRect().height / 2,
      }
    },
    offsetEye2() {
      return {
        left:
          this.$refs.eye2.getBoundingClientRect().left +
          this.$refs.eye2.getBoundingClientRect().width / 2,
        top:
          this.$refs.eye2.getBoundingClientRect().top +
          this.$refs.eye2.getBoundingClientRect().height / 2,
      }
    },
  },
  methods: {
    onMouseMove(event) {
      let dx1 = event.clientX - this.offsetEye1.left
      let dy1 = event.clientY - this.offsetEye1.top

      let dx2 = event.clientX - this.offsetEye2.left
      let dy2 = event.clientY - this.offsetEye2.top

      this.$refs.eye1.style.transform = 'rotate(' + arcctg(dx1, dy1) + 'rad)'
      this.$refs.eye2.style.transform = 'rotate(' + arcctg(dx2, dy2) + 'rad)'
    },
  },
  mounted() {
    document.addEventListener('mousemove', this.onMouseMove)
  },
  beforeDestroy() {
    document.removeEventListener('mousemove', this.onMouseMove)
  },
}
</script>

<style lang="scss" src="../scss/error404.scss" scoped></style>
